<template>
  <div
    v-if="infoInRetour"
    :class="`modal ${
      !infoInRetour && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="infoInRetour = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-11/12 mx-auto overflow-hidden overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 80rem; height: 30rem; max-height: 40rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <div
        v-if="detailRetour != null"
        class="px-6 py-4 text-center modal-content"
      >
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Détails du retour</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="infoInRetour = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!-- Total retour -->
        <div
          class="flex flex-col px-2"
          v-if="detailRetour.requestedTotalretour"
        >
          <div class="flex justify-end space-x-2 items-center">
            <p class="text-blue-600">Total demandé :</p>
            <h4 class="font-medium text-xl">
              {{ financial(detailRetour.requestedTotalretour) }} € HT
            </h4>
            <p>soit</p>
            <h4>
              {{
                financial(
                  detailRetour.requestedTotalretour +
                    detailRetour.requestedTotalretour * 0.2
                )
              }}
              € TTC
            </h4>
          </div>
          <div
            v-if="detailRetour.retourstate[2] || detailRetour.retourstate[3]"
            class="flex justify-end space-x-2 items-center"
          >
            <p class="text-green-600">Total accepté :</p>
            <h4 class="font-medium text-xl">
              {{ parseFloat(detailRetour.Totalretour).toFixed(2) }} € HT
            </h4>
            <p>soit</p>
            <h4>
              {{
                (
                  parseFloat(detailRetour.Totalretour) +
                  parseFloat(detailRetour.Totalretour) * 0.2
                ).toFixed(2)
              }}
              € TTC
            </h4>
          </div>
        </div>
        <div class="flex flex-col px-2 text-rigth" v-else>
          <div class="flex space-x-2 items-center">
            <p class="text-green-600">Total :</p>
            <h4 class="font-medium text-xl">
              {{ parseFloat(detailRetour.Totalretour).toFixed(2) }} € HT
            </h4>
            <p>soit</p>
            <h4>
              {{
                (
                  parseFloat(detailRetour.Totalretour) +
                  parseFloat(detailRetour.Totalretour) * 0.2
                ).toFixed(2)
              }}
              € TTC
            </h4>
          </div>
        </div>

        <!-- Tableau detailRetour.pdfData -->
        <div
          class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        >
          <div
            class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg h-full"
          >
            <table class="min-w-full">
              <thead class="tableHEADTR">
                <tr>
                  <th
                    class="w-44 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Réf.
                  </th>
                  <th
                    class="w-80 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Description / Marque
                  </th>
                  <th
                    class="w-36 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    <p
                      v-if="
                        detailRetour.retourstate[2] ||
                        detailRetour.retourstate[3]
                      "
                    >
                      Qté acceptée / Qté demandée
                    </p>
                    <p v-else>Qté retournée</p>
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    P.V.
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Remise
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Px final
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Total
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white">
                <tr
                  v-for="(r, rIndex) in detailRetour.requestedArticles"
                  :key="rIndex"
                  class="tableHEADTR"
                >
                  <!-- Référence -->
                  <td
                    class="px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH w-44"
                  >
                    {{ r.Ref_fournisseur }}
                  </td>

                  <!-- Description / Marque -->
                  <td
                    class="w-80 px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="text-sm leading-5 whitespace-normal w-fit">
                      {{ r.Description }}
                    </div>
                    <div class="text-xs leading-5 text-gray-900">
                      {{ detailRetour.requestedArticles[rIndex].Code_marque }}
                    </div>
                  </td>

                  <!-- Quantité -->
                  <td
                    class="w-36 px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div
                      class="my-auto inline-flex px-2 font-semibold leading-5 text-gray-800 font-medium"
                      v-if="
                        detailRetour.retourstate[2] ||
                        detailRetour.retourstate[3]
                      "
                    >
                      <div class="flex flex-col">
                        <div class="flex space-x-2">
                          <p class="my-auto mr-4">
                            {{
                              detailRetour.requestedArticles[rIndex].quantity
                            }}
                          </p>
                          <p
                            v-if="
                              detailRetour.requestedArticles[rIndex]
                                .refusalQuantity &&
                              detailRetour.requestedArticles[rIndex]
                                .refusalQuantity.value == true
                            "
                          >
                            /
                            {{
                              detailRetour.requestedArticles[rIndex]
                                .refusalQuantity.requestedQuantity
                            }}
                          </p>
                          <p
                            v-else
                            class="my-auto inline-flex px-2 font-semibold leading-5 text-gray-800"
                          >
                            /
                            {{
                              detailRetour.requestedArticles[rIndex]
                                .requestedQuantity
                            }}
                          </p>
                        </div>
                        <div
                          v-if="
                            detailRetour.requestedArticles[rIndex]
                              .refusalQuantity &&
                            detailRetour.requestedArticles[rIndex]
                              .refusalQuantity.value == true
                          "
                          class="flex flex-col text-xs"
                        >
                          <p
                            class="bg-orange-500 text-white w-fit font-bold px-0.5"
                          >
                            Qté demandée :
                            {{
                              detailRetour.requestedArticles[rIndex]
                                .refusalQuantity.requestedQuantity
                            }}
                          </p>
                          <p class="bg-red-500 text-white w-fit px-0.5 mt-1">
                            Qté refusée :
                            {{
                              detailRetour.requestedArticles[rIndex]
                                .refusalQuantity.quantity
                            }}
                          </p>
                          <p class="bg-blue-500 text-white w-fit px-0.5 mt-1">
                            Raison :
                            {{
                              detailRetour.requestedArticles[rIndex]
                                .refusalQuantity.reason
                            }}
                          </p>
                          <p>
                            Par:
                            {{
                              detailRetour.requestedArticles[rIndex]
                                .refusalQuantity.user
                            }}
                          </p>
                          <p>
                            Le:
                            {{
                              detailRetour.requestedArticles[rIndex]
                                .refusalQuantity.date
                            }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <p
                      v-else
                      class="my-auto font-medium inline-flex px-2 font-semibold leading-5 text-gray-800"
                    >
                      {{
                        detailRetour.requestedArticles[rIndex].requestedQuantity
                      }}
                    </p>
                  </td>

                  <!-- prix vente -->
                  <td
                    class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{
                      financial(
                        detailRetour.requestedArticles[rIndex].Prix_euro
                      )
                    }}
                    €
                  </td>

                  <!-- remise / prix net -->
                  <td
                    class="px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex text-sm leading-5 text-gray-900">
                      <div class="flex">
                        <p
                          v-if="
                            detailRetour.requestedArticles[rIndex]
                              .PrixFournisseurType != 'prixnet'
                          "
                        >
                          {{
                            financial(
                              detailRetour.requestedArticles[rIndex].remise
                            )
                          }}
                          %
                        </p>
                        <p v-else>
                          {{
                            detailRetour.requestedArticles[rIndex]
                              .PrixFournisseurType
                          }}
                        </p>
                      </div>
                    </div>
                  </td>

                  <!-- prix final -->
                  <td
                    class="px-6 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex space-x-1 text-sm leading-5 text-gray-900">
                      <p
                        v-if="
                          detailRetour.requestedArticles[rIndex]
                            .PrixFournisseurType != 'prixnet'
                        "
                      >
                        {{
                          financial(
                            detailRetour.requestedArticles[rIndex].Prix_euro -
                              detailRetour.requestedArticles[rIndex].Prix_euro *
                                (detailRetour.requestedArticles[rIndex].remise /
                                  100)
                          )
                        }}
                      </p>
                      <p v-else>
                        {{
                          financial(
                            detailRetour.requestedArticles[rIndex].Prix_vente
                          )
                        }}
                      </p>

                      <p>€</p>
                    </div>
                  </td>

                  <!-- total -->
                  <td
                    class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex space-x-1 text-sm leading-5 text-gray-900">
                      <span
                        v-if="
                          detailRetour.requestedArticles[rIndex]
                            .PrixFournisseurType != 'prixnet'
                        "
                      >
                        {{
                          financial(
                            (detailRetour.requestedArticles[rIndex].Prix_euro -
                              detailRetour.requestedArticles[rIndex].Prix_euro *
                                (detailRetour.requestedArticles[rIndex].remise /
                                  100)) *
                              detailRetour.requestedArticles[rIndex].quantity
                          )
                        }}
                      </span>
                      <span v-else>
                        {{
                          financial(
                            detailRetour.requestedArticles[rIndex].Prix_vente *
                              detailRetour.requestedArticles[rIndex].quantity
                          )
                        }}
                      </span>
                      <span>€</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- BODY -->
  <div class="flex space-x-12">
    <searchInput
      v-if="archiveState == true"
      placeholder="Rechercher dans la liste des retours"
      @searchWord="search(1, 10, $event)"
      @catchFalse="searchCatchError = $event"
      :searchStarted="searchInProgress"
      :catchError="searchCatchError"
    />
    <searchInput
      v-else
      placeholder="Rechercher dans la liste des retours"
      @searchWord="search(1, 10, $event)"
      @catchFalse="searchCatchError = $event"
      :searchStarted="searchInProgress"
      :catchError="searchCatchError"
    />

    <button
      class="my-auto flex justify-between p-2 shadow-lg font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
      @click="$router.push('/demandeRetour')"
      title="Faire une demande de retour"
    >
      <p>Nouveau retour</p>
      <span class="material-icons-round ml-2">keyboard_return</span>
    </button>
    <titrePages class="flex justify-center flex-1" :title="'Retours'" />

    <!-- <button
      v-if="showBtnFusion"
      class="p-2 bg-blue-500 text-white rounded shadow"
      @click="getMergedPdf()"
    >
      Fusionner et ouvrir PDF
    </button> -->
  </div>

  <div class="flex mt-4 space-x-4">
    <button
      v-if="archiveState == false"
      @click="setRetourType(false)"
      class="bg-bluevk text-white p-2 rounded-md w-24 border border-bluevk"
    >
      En cours
    </button>
    <button
      v-else
      @click="setRetourType(false)"
      class="bg-bluevk text-white p-2 rounded-md w-24 bg-opacity-50 border border-white hover:bg-opacity-80"
    >
      En cours
    </button>
    <button
      v-if="archiveState == true"
      @click="setRetourType(true)"
      class="bg-bluevk text-white p-2 rounded-md w-24 border border-bluevk"
    >
      Archivés
    </button>
    <button
      v-else
      @click="setRetourType(true)"
      class="bg-bluevk text-white p-2 rounded-md w-24 bg-opacity-50 border border-white hover:bg-opacity-80"
    >
      Archivés
    </button>
  </div>

  <div class="flex flex-col">
    <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full">
          <thead>
            <tr>
              <!-- <th
                style="width: 1rem"
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                <span> Sélect.</span>
              </th> -->
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Type retour
                <div class="flex items-center space-x-3">
                  <span class="text-blue-800">Pièces</span>
                  <label
                    class="relative inline-flex items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      v-model="stateTypeRetour"
                      @change="setRetourType(archiveState)"
                      class="sr-only peer"
                    />
                    <div
                      class="w-11 h-6 bg-blue-700 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-blue-800 peer-checked:bg-orange-500"
                    ></div>
                    <span class="ml-3 text-orange-500">Consignes</span>
                  </label>
                </div>
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Commentaire
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Date
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Num. BR
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Détail
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Demandé par
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Statut
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                HT
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                TTC
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                BR
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                BL avoir
              </th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <tr
              v-for="(u, index) in infosRetour"
              :key="index"
              class="cursor-pointer hover:bg-gray-100"
            >
              <!-- <td
                class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                style="width: 1rem"
              >
                <input
                  type="checkbox"
                  class="focus:outline-2 outline-sky-300"
                  :value="u"
                  v-model="selectedRetour"
                />
              </td> -->

              <!-- type retour -->
              <td
                class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
              >
                {{ u.typeRetour }}
              </td>

              <!-- commentaire -->
              <td
                class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
              >
                {{ u.Note }}
              </td>

              <!-- date -->
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <div class="flex items-center">
                  <div class="ml-4">
                    <div class="text-sm font-medium leading-5 text-gray-900">
                      {{ getFRDate(u.Date) }}
                    </div>
                  </div>
                </div>
              </td>

              <!-- numero BR -->
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <div class="text-sm leading-5 text-gray-900">
                  {{ u.NumeroBR }}
                </div>
              </td>

              <!-- détail -->
              <td
                class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
              >
                <span
                  @click="getDetailRetour(u)"
                  title="Plus d'informations"
                  class="material-icons-outlined text-2xl text-cyan-600 hover:text-cyan-300 my-auto"
                >
                  info
                </span>
              </td>

              <!-- utilisateur -->
              <td
                class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
              >
                {{ u.Name }}
              </td>

              <!-- statut -->
              <td
                class="px-6 py-4 text-center text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
              >
                <progressBar
                  v-if="u.status == 'Retour annulé'"
                  :pourcentageStatus="parseInt(u.PourcentageStatus)"
                  :isRed="true"
                />
                <progressBar
                  v-else
                  :pourcentageStatus="parseInt(u.PourcentageStatus)"
                  :isRed="false"
                />
                {{ u.status }}
                <div
                  v-if="u.status == 'Retour annulé' && u.cancellation"
                  class="text-xs"
                  :title="u.cancellation.reason"
                >
                  <h1>
                    Raison d'annulation :
                    {{
                      u.cancellation.reason.length > 11
                        ? u.cancellation.reason.substr(0, 11) + "..."
                        : u.cancellation.reason
                    }}
                  </h1>
                  <h1>Par : {{ u.cancellation.user }}</h1>
                </div>
                <div
                  v-if="u.remainder && u.remainder.value"
                  class="text-xs"
                  :title="u.remainder.reason"
                >
                  <h1>
                    Raison du reliquat :
                    {{
                      u.remainder.reason.length > 11
                        ? u.remainder.reason.substr(0, 11) + "..."
                        : u.remainder.reason
                    }}
                  </h1>
                  <h1>Par : {{ u.remainder.user }}</h1>
                </div>
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              >
                {{ parseFloat(u.Totalretour).toFixed(2) }} €
              </td>

              <td
                v-if="u.TotalretourTTC"
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              >
                {{ parseFloat(u.TotalretourTTC).toFixed(2) }} €
              </td>
              <td
                v-else
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              >
                {{ parseFloat(u.Totalretour + u.Totalretour * 0.2).toFixed(2) }}
                €
              </td>

              <!-- BR -->
              <td
                class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
              >
                <div v-if="u.NumeroBR" class="flex flex-col items-center">
                  <span
                    class="material-icons-round cursor-pointer text-blue-600 text-3xl hover:text-opacity-25"
                    @click="createRetour(u)"
                  >
                    download
                  </span>
                  <p class="mx-auto text-xs">BR n° {{ u.NumeroBR }}</p>
                </div>
              </td>

              <!-- BL Avoir -->
              <td
                class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
              >
                <div
                  v-if="u.type == 'Facture' && u.status == 'Retour validé'"
                  class="flex space-x-4"
                >
                  <span
                    class="material-icons-round cursor-pointer text-blue-600 text-3xl hover:text-opacity-25"
                    @click="createPdfFacture(u)"
                  >
                    download
                  </span>
                </div>
                <!-- <div
                  v-if="u.type == 'BL' && u.status == 'Retour validé'"
                  class="flex space-x-4"
                >
                  <span
                    class="material-icons-round cursor-pointer text-blue-600 text-3xl hover:text-opacity-25"
                    @click="createPDF(u)"
                  >
                    download
                  </span>
                </div> -->

                <div v-if="u.numeroAvoir" class="flex flex-col items-center">
                  <span
                    class="material-icons-round cursor-pointer text-blue-600 text-3xl hover:text-opacity-25"
                    @click="createPDF(u)"
                  >
                    download
                  </span>
                  <p class="mx-auto text-xs">BL n° {{ u.numeroAvoir }}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Pagination -->
        <pageNumber
          v-if="!searchActive"
          :pageStart="pageStart"
          :pageNext="pageNext"
          :tabList="infosRetourLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(infosRetourLength / 10)"
          :clickFunction="
            (arg1, arg2) => getInfosRetour(arg1, arg2, archiveState)
          "
          :currentPage="currentPage"
          :typeList="'retours'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />

        <!-- Pagination actif searchActive -->
        <pageNumber
          v-if="searchActive"
          :pageStart="pageStart"
          :pageNext="pageNext"
          :tabList="searchLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(searchLength / 10)"
          :clickFunction="(arg1, arg2) => search(arg1, arg2, searchTerm)"
          :currentPage="currentPage"
          :typeList="'retours'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { createfacture } from "@/components/pdf/facture";
import { createlivraison } from "@/components/pdf/livraison";
import { createretour } from "@/components/pdf/retour.js";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import ProgressBar from "@/components/Commandes/progressBar.vue";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";
import titrePages from "@/components/Text/grandeTitre.vue";

export default {
  name: "MyRetour",
  data() {
    return {
      infosRetour: [],
      infoInRetour: false,
      detailRetour: null,
      stateTypeRetour: false,

      // selectedRetour: [],

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      infosRetourLength: 0,
      currentPage: 1,

      searchActive: false,
      searchTerm: "",
      searchLength: 0,
      searchInProgress: false,
      searchCatchError: false,

      archiveState: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    // showBtnFusion() {
    //   var showBtnFusion = false;
    //   if (this.selectedRetour.length > 0) {
    //     showBtnFusion = true;
    //   } else {
    //     showBtnFusion = false;
    //   }
    //   return showBtnFusion;
    // },
  },
  components: {
    pageNumber,
    searchInput,
    ProgressBar,
    titrePages,
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    getInfosRetour(skipValue, limitValue, archive) {
      this.currentPage = skipValue;
      this.archiveState = archive;
      axios
        .post(`${process.env.VUE_APP_API}/retour/getByClient/getpage`, {
          plateform: this.user.plateform,
          client: this.user.proprietaire,
          archive: archive,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          this.searchTerm = "";
          this.infosRetour = response.data;
          this.getInfosRetourLength();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfosRetourLength() {
      axios
        .post(`${process.env.VUE_APP_API}/retour/getByClient/count`, {
          plateform: this.user.plateform,
          client: this.user.proprietaire,
          archive: this.archiveState,
        })
        .then((response) => {
          this.infosRetourLength = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfosRetourConsigne(skipValue, limitValue, archive) {
      console.log(archive);
      this.currentPage = skipValue;
      this.archiveState = archive;
      axios
        .post(`${process.env.VUE_APP_API}/retourConsigne/getByClient/getpage`, {
          plateform: this.user.plateform,
          client: this.user.proprietaire,
          archive: archive,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          this.searchTerm = "";
          this.infosRetour = response.data;
          this.getInfosRetourConsigneLength();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfosRetourConsigneLength() {
      axios
        .post(`${process.env.VUE_APP_API}/retourConsigne/getByClient/count`, {
          plateform: this.user.plateform,
          client: this.user.proprietaire,
          archive: this.archiveState,
        })
        .then((response) => {
          this.infosRetourLength = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDetailRetour(u) {
      this.detailRetour = u;
      this.infoInRetour = true;
    },
    createPdfFacture(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createfacture(
          {
            Name: this.user.username,
            Owner: this.user.proprietaire,
            Date: objet.Date,
            Numero: objet.numeroAvoir,
            TotalPrice: objet.Totalretour,
            TotalPriceTTC: objet.Totalretour + objet.Totalretour * 0.2,
            Client: objet.Client,
            IsProcessed: false,
            Note: objet.Note,
            Articles: objet.requestedArticles,
            reglement: objet.reglement,
            pdfData: objet.requestedPdfData,
            clientInfo: objet.clientInfo,
            Transport: 0,
          },
          false,
          true
        );
      } else {
        createfacture(
          {
            Name: this.user.username,
            Owner: this.user.proprietaire,
            Date: objet.Date,
            Numero: objet.numeroAvoir,
            TotalPrice: objet.Totalretour,
            TotalPriceTTC: objet.Totalretour + objet.Totalretour * 0.2,
            Client: objet.Client,
            IsProcessed: false,
            Note: objet.Note,
            Articles: objet.requestedArticles,
            reglement: objet.reglement,
            pdfData: objet.requestedPdfData,
            clientInfo: objet.clientInfo,
            Transport: 0,
          },
          true,
          true
        );
      }
    },
    createPDF(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createlivraison(
          {
            Name: this.user.username,
            Owner: this.user.proprietaire,
            Date: objet.Date,
            Numero: objet.numeroAvoir,
            Archived: false,
            TotalPrice: objet.Totalretour,
            TotalPriceTTC: objet.Totalretour + objet.Totalretour * 0.2,
            Client: objet.Client,
            IsProcessed: false,
            Note: objet.Note,
            Articles: objet.requestedArticles,
            reglement: [],
            pdfData: objet.requestedPdfData,
            clientInfo: objet.clientInfo,
            Transport: 0,
          },
          false,
          true
        );
      } else {
        createlivraison(
          {
            Name: this.user.username,
            Owner: this.user.proprietaire,
            Date: objet.Date,
            Numero: objet.numeroAvoir,
            Archived: false,
            TotalPrice: objet.Totalretour,
            TotalPriceTTC: objet.Totalretour + objet.Totalretour * 0.2,
            Client: objet.Client,
            IsProcessed: false,
            Note: objet.Note,
            Articles: objet.requestedArticles,
            reglement: [],
            pdfData: objet.requestedPdfData,
            clientInfo: objet.clientInfo,
            Transport: 0,
          },
          true,
          true
        );
      }
    },
    async setRetourType(archive) {
      this.searchTerm = "";
      this.searchActive = false;
      if (this.stateTypeRetour) {
        await this.getInfosRetourConsigne(1, 10, archive);
      } else {
        await this.getInfosRetour(1, 10, archive);
      }
    },
    // getMergedPdf() {
    //   axios
    //       .post(`${process.env.VUE_APP_API}/retour/getMergedPdf`, {
    //         plateform: this.user.plateform,
    //         client: this.user.proprietaire,
    //         data: this.selectedRetour,
    //       })
    //       .then((response) => {
    //         console.log(response.data);
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    // },
    createRetour(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.DateBR);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createretour(objet, objet.typeRetour, false);
      } else {
        createretour(objet, objet.typeRetour, true);
      }
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.setRetourType(this.archiveState);
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        var typeRetour;
        if (this.stateTypeRetour) {
          typeRetour = "retourConsigne";
        } else {
          typeRetour = "retour";
        }
        axios
          .post(`${process.env.VUE_APP_API}/${typeRetour}/searchInClient`, {
            plateform: this.user.plateform,
            client: this.user.proprietaire,
            searchTerm: searchTerm,
            archive: this.archiveState,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          })
          .then((response) => {
            this.searchInProgress = false;
            this.infosRetour = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
  },
  mounted() {
    this.getInfosRetour(1, 10, false);
    this.pageSwitch();
  },
};
</script>

<style></style>
