<template>
  <div
    v-if="openCreerClient"
    :class="`modal ${
      !openCreerClient && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openCreerClient = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: fit-content; max-height: 52rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openCreerClient = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <creerClient
          v-if="openCreerClient"
          @close="openCreerClient = !openCreerClient"
          @isDataClient="getClients()"
        />
        <!-- @client="handleClient"
          @clientInfos="handleClientInfos" -->
      </div>
    </div>
  </div>

  <div
    v-if="openDetailClient"
    :class="`modal ${
      !openDetailClient && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="(openDetailClient = false), getClients()"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: fit-content; max-height: 52rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div
            class="z-50 cursor-pointer modal-close"
            @click="(openDetailClient = false), getClients()"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <detailClient v-if="openDetailClient" />
        <button
          class="mt-4 hover:bg-gray-50 hover:shadow-sm rounded-md text-red-500 px-4"
          @click="deleteClient(clientChoosedTab._id)"
        >
          Supprimer le client
        </button>
        <!-- @client="handleClient"
          @clientInfos="handleClientInfos" -->
      </div>
    </div>
  </div>

  <div>
    <div class="flex justify-between">
      <button
        @click="$router.back()"
        id="retour"
        class="flex bg-bluevk text-white rounded-lg shadow-lg px-2 py-1 w-fit hover:bg-blue-500 border border-blue-400 my-auto"
      >
        <div class="flex items-center space-x-1 my-auto">
          <span class="material-icons-outlined">keyboard_backspace</span>
          <p class="font-medium mb-1">Retour</p>
        </div>
      </button>
      <titrePages class="flex justify-center flex-1" :title="'Clients'" />
      <button
        @click="openCreerClient = true"
        class="bg-bluevk hover:bg-blue-500 rounded-lg text-white px-2 py-1 shadow-lg"
      >
        Ajouter un client
      </button>
    </div>

    <div
      class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2"
    >
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                N° Client
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Nom du client
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Adresse
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Code postal
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Ville
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Email / Tél.
              </th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <tr
              v-for="(u, index) in dataClient"
              :key="index"
              class="tab-list hover:shadow-lg cursor-pointer odd:bg-white even:bg-slate-50"
              @click="getClientInfo(u)"
            >
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <div class="flex items-center">
                  <div class="ml-4">
                    <div class="text-sm font-medium leading-5 text-gray-900">
                      {{ u.Numero }}
                    </div>
                  </div>
                </div>
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <span
                  class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 rounded-full"
                >
                  {{ u.Name }}
                </span>
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ u.Adresse }}
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ u.PostalCode }}
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              >
                {{ u.City }}
              </td>

              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap text-sm"
              >
                <div class="flex flex-col">
                  <span class="font-medium">{{ u.telephone }}</span>
                  <span class="text-blue-800">
                    {{ u.email }}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import creerClient from "../components/clientInfo/creerClient.vue";
import detailClient from "../components/clientInfo/detailClient.vue";
import titrePages from "@/components/Text/grandeTitre.vue";
export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      openCreerClient: false,
      openDetailClient: false,
      dataClient: [],
    };
  },
  components: {
    creerClient,
    detailClient,
    titrePages,
  },
  computed: {
    ...mapGetters(["user", "clientChoosedTab"]),
  },
  methods: {
    ...mapActions(["getclientchoosedtab"]),
    getClients() {
      axios
        .post(`${process.env.VUE_APP_API}/clientSpaceClient/getbyowner`, {
          plateform: this.user.plateform,
          Client: this.user.proprietaire,
        })
        .then((response) => {
          this.dataClient = response.data;
        });
    },
    getClientInfo(client) {
      this.getclientchoosedtab(client);
      this.openDetailClient = !this.openDetailClient;
    },
    deleteClient(id) {
      axios
        .put(`${process.env.VUE_APP_API}/clientSpaceClient/removeClient/${id}`)
        // axios
        //   .put(`${process.env.VUE_APP_API}/devisClient/removeDevis/${id}`)
        .then(() => {
          this.dataClient.forEach((element, index) => {
            if (element._id == id) {
              this.dataClient.splice(index, 1);
            }
          });
          this.openDetailClient = !this.openDetailClient;
          this.toast.success("Client supprimé !", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
  },
  mounted() {
    this.getClients();
  },
};
</script>

<style>
.tab-list:hover:nth-child(odd) {
  background-color: rgb(229 231 235);
  /* opacity: 0.6; */
}

.tab-list:hover:nth-child(even) {
  background-color: rgb(229 231 235);
  /* opacity: 0.6; */
}
</style>
