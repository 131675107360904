<template>
  <div>
    <!-- Modal Detail Articles -->
    <div
      v-if="showDetailArticles"
      :class="`modal ${
        !showDetailArticles && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="showDetailArticles = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 70rem"
        class="z-50 w-11/12 mx-auto overflow-y-auto max-h-[50rem] bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Récapitulatif du devis</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="showDetailArticles = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <div class="flex justify-end">
            <div class="flex flex-col w-8 h-8 justify-end mx-12">
              <span
                class="material-icons-round cursor-pointer shadow-lg rounded-full p-1"
                style="color: blue"
                @click="showDevis(cmdTmp)"
              >
                download
              </span>
              <span
                class="cursor-pointer text-xs m-auto text-gray-700"
                @click="showDevis(cmdTmp)"
              >
                {{ cmdTmp.Numero }}
              </span>
            </div>
          </div>
          <!--Body-->
          <table class="min-w-full mt-4">
            <div class="overflow-y-auto max-h-[25rem]">
              <thead class="tableHEADTR">
                <tr>
                  <th
                    class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Réf.
                  </th>
                  <th
                    class="mx-20 w-80 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Description
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Qté
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    P.V.
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Remise
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Px final
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Total
                  </th>
                </tr>
              </thead>

              <tbody class="bg-white w-fit">
                <tr
                  v-for="(r, index) in Articles"
                  :key="index"
                  class="tableHEADTR"
                >
                  <td
                    class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex flex-row items-center">
                      <span class="ml-4 text-sm leading-5 text-gray-900">
                        {{ r.ref }}
                      </span>
                    </div>
                  </td>

                  <td
                    class="px-6 w-80 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                    style="overflow: none"
                  >
                    <div class="text-sm leading-5 text-gray-500">
                      {{ r.description }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="text-sm leading-5">
                      {{ r.quantite }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{ parseFloat(r.prixVente).toFixed(2) }} €
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{ r.remise }} %
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{
                      (
                        parseFloat(r.prixVente) -
                        parseFloat(r.prixVente) * (r.remise / 100)
                      ).toFixed(2)
                    }}
                    €
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{
                      (
                        (parseFloat(r.prixVente) -
                          parseFloat(r.prixVente) * (r.remise / 100)) *
                        r.quantite
                      ).toFixed(2)
                    }}
                    €
                  </td>
                </tr>
              </tbody>
            </div>
            <thead class="tableHEADTR mt-12 border-t">
              <tr class="tableHEADTR">
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="w-64 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    Total HT : {{ parseFloat(tmpTotalPrice).toFixed(2) }} €
                  </div>
                  <div class="text-sm leading-5 text-gray-500">
                    Total TTC : {{ parseFloat(tmpTotalPriceTTC).toFixed(2) }} €
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
              </tr>
            </thead>
          </table>

          <!--Footer-->
          <div class="flex justify-between mt-2">
            <button
              class="hover:bg-gray-50 hover:shadow-sm rounded-md text-red-500 px-4"
              @click="deleteDevis(cmdTmp._id)"
            >
              Supprimer le devis
            </button>
            <button
              @click="showDetailArticles = false"
              class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin modal Detail Articles -->

    <div class="flex justify-between mb-2">
      <searchInput
        placeholder="Rechercher dans devis"
        @searchWord="search(1, 10, $event)"
        @catchFalse="searchCatchError = $event"
        :searchStarted="searchInProgress"
        :catchError="searchCatchError"
      />
      <titrePages class="flex justify-center flex-1" :title="'Devis client'" />
      <a href="./listeClient">
        <button
          class="bg-blue-500 hover:bg-blue-400 rounded-md text-white font-medium px-2 py-1 shadow-lg"
        >
          Voir les clients
        </button>
      </a>
    </div>
    <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Date
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Numéro
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Client
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Créé par
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                HT
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                TTC
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Devis
              </th>
              <th
                class="py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Reprise
              </th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <tr
              v-for="(u, index) in infosDevis"
              :key="index"
              class="hover:shadow-lg cursor-pointer tab-list odd:bg-white even:bg-slate-50"
              @click="getDetailArticles(u)"
            >
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <div class="text-sm leading-5 text-gray-900">
                  {{ getFRDate(u.Date) }}
                </div>
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <div class="text-sm leading-5 text-gray-900">
                  {{ u.Numero }}
                  <span
                    @click="showDetailArticles = true"
                    title="Plus d'informations"
                    class="material-icons-outlined text-base text-cyan-600"
                  >
                    info
                  </span>
                </div>
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <span
                  class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 rounded-full"
                >
                  {{ u.Client }}
                </span>
              </td>

              <td
                class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
              >
                <span
                  class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                  >{{ u.Name }}</span
                >
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              >
                {{ parseFloat(u.TotalPrice).toFixed(2) }} €
              </td>

              <td
                v-if="u.TotalPriceTTC"
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              >
                {{ parseFloat(u.TotalPriceTTC).toFixed(2) }} €
              </td>
              <td
                v-else
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              >
                {{ parseFloat(u.TotalPrice + u.TotalPrice * 0.2).toFixed(2) }}
                €
              </td>

              <td
                class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap"
              >
                <span
                  class="material-icons-round cursor-pointer shadow-lg rounded-full p-1"
                  style="color: blue"
                  @click="showDevis(u)"
                >
                  download
                </span>
              </td>

              <td
                class="py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap"
              >
                <div class="flex flex-col space-y-1">
                  <button
                    v-if="loadingCmder[index]"
                    class="shadow-md rounded-md px-1 bg-green-500 hover:bg-green-400 text-white w-24"
                  >
                    <PulseLoader color="#ffff" />
                  </button>
                  <button
                    v-else
                    @click="getCommander(u, index)"
                    class="shadow-md rounded-md px-1 bg-green-500 hover:bg-green-400 text-white w-24"
                  >
                    Commander
                  </button>
                  <button
                    @click="getModifier(u)"
                    class="shadow-md rounded-md px-1 bg-orange-500 hover:bg-orange-400 text-white w-24"
                  >
                    Dupliquer
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <pageNumber
          v-if="!searchActive"
          :pageStart="pageStart"
          :pageNext="pageNext"
          :tabList="infosDevisLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(infosDevisLength / 10)"
          :clickFunction="getInfosDevis"
          :currentPage="currentPage"
          :typeList="'devis'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />

        <pageNumber
          v-if="searchActive"
          :pageStart="pageStart"
          :pageNext="pageNext"
          :tabList="searchLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(searchLength / 10)"
          :clickFunction="(arg1, arg2) => search(arg1, arg2, searchTerm)"
          :currentPage="currentPage"
          :typeList="'devis'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import { mapActions, mapGetters } from "vuex";
import { createdevis } from "../../components/pdf/devisClient.js";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";
import {
  searchArticleClient,
  prepareArticleClient,
} from "@/hooks/searchEngine/article.ts";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import titrePages from "@/components/Text/grandeTitre.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      infosDevis: [],
      showDetailArticles: false,
      Articles: [],
      cmdTmp: [],
      tmpTotalPrice: "",
      tmpTotalPriceTTC: "",
      numDoc: "",

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,
      infosDevisLength: 0,

      searchActive: false,
      searchTerm: "",
      searchLength: 0,
      searchInProgress: false,
      searchCatchError: false,

      loadingCmder: [],
    };
  },
  components: {
    pageNumber,
    searchInput,
    PulseLoader,
    titrePages,
  },
  computed: {
    ...mapGetters(["user", "plateform", "tabClient", "clientChoosed"]),
  },
  methods: {
    ...mapActions([
      "addactionArticle",
      "clearactionArticle",
      "adddatadevismodif",
      "cleardatadevis",
      "savedatadevis",
      "adddataconsigne",
      "adddatanonconsigne",
    ]),
    getFRDate(date) {
      return getFrDate(date);
    },
    getInfosDevis(skipValue, limitValue) {
      this.currentPage = skipValue;
      axios
        .post(
          `${process.env.VUE_APP_API}/devisSpaceClient/getbyowner/getpage`,
          {
            plateform: this.user.plateform,
            Client: this.user.proprietaire,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          }
        )
        .then((response) => {
          this.infosDevis = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfosDevisLength() {
      axios
        .post(`${process.env.VUE_APP_API}/devisSpaceClient/getbyowner/count`, {
          plateform: this.user.plateform,
          Client: this.user.proprietaire,
        })
        .then((response) => {
          this.infosDevisLength = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDetailArticles(u) {
      this.cmdTmp = u;
      this.Articles = u.pdfData;
      this.tmpTotalPrice = u.TotalPrice;
      this.tmpTotalPriceTTC = u.TotalPriceTTC;
      this.numDoc = u.Numero;
    },
    deleteDevis(id) {
      axios
        .put(`${process.env.VUE_APP_API}/devisSpaceClient/removeDevis/${id}`)
        // axios
        //   .put(`${process.env.VUE_APP_API}/devisClient/removeDevis/${id}`)
        .then(() => {
          this.infosDevis.forEach((element, index) => {
            if (element._id == id) {
              this.infosDevis.splice(index, 1);
            }
          });
          this.showDetailArticles = !this.showDetailArticles;
          this.getInfosDevis(this.currentPage, 10);
          this.getInfosDevisLength();
          this.toast.success("Devis supprimé !", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    showDevis(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      console.log(objet);
      if (dateedition == datepdf) {
        createdevis(objet, false);
      } else {
        createdevis(objet, true);
      }
    },
    async getCommander(objet, index) {
      this.loadingCmder[index] = true;
      this.clearactionArticle();
      // this.savedatadevis({ refClient: objet.Note });

      for (const artBdl of objet.Articles) {
        {
          if (
            artBdl.Ref_fournisseur &&
            artBdl.Prefixe_tarif &&
            artBdl.Code_marque &&
            !artBdl?.Ref_fournisseur?.startsWith("C : ")
          ) {
            let plateform_mere = this.user.plateform;
            if (this.plateform.plateform_mere) {
              plateform_mere = this.plateform.plateform_mere;
            }

            var searchTab = await searchArticleClient(
              artBdl.Ref_fournisseur,
              plateform_mere,
              this.user.proprietaire,
              this.tabClient.Group.Name
            );

            const structure = searchTab.filter(
              (ligne) => (
                ligne.article.plateform === artBdl.plateform,
                ligne.article.Ref_fournisseur === artBdl.Ref_fournisseur,
                ligne.article.Prefixe_tarif === artBdl.Prefixe_tarif,
                ligne.article.Code_marque === artBdl.Code_marque
              )
            );

            var dataTab = await prepareArticleClient(
              structure[0],
              plateform_mere,
              this.tabClient.Name,
              this.tabClient.Group.Name,
              artBdl.quantity
            );

            await this.addConsigne(dataTab);
            await this.addactionArticle(dataTab);
          }
        }
      }
      this.loadingCmder[index] = false;
      this.$router.push("./commandesf");
    },
    async addConsigne(dataConsigne) {
      const foundConsigne = await axios.post(
        `${process.env.VUE_APP_API}/article/getconsigne`,
        {
          data: dataConsigne,
        }
      );
      if (foundConsigne.data.length > 0) {
        // if (this.dsvCheck == false) {
        this.addactionArticle({
          _id: dataConsigne._id,
          Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
          Prix_euro: dataConsigne.Montant_consigne,
          Prix_vente: dataConsigne.Montant_consigne,
          PrixFournisseurType: "prixnet",
          disabled: true,
          quantity: dataConsigne.quantity,
          quantityPrep: 0,
          quantityReturned: 0,
          remise: 0,
          prixtotal: dataConsigne.Prix_euro,
          prixfinal: dataConsigne.Prix_euro,
          Code_marque: dataConsigne.Code_marque,
          Description: dataConsigne.Description,
          commentaire: dataConsigne.commentaire,
        });
        // }
        if (dataConsigne.Code_marque == "DA SILVA") {
          this.adddataconsigne({
            _id: dataConsigne._id,
            Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
            Prix_euro: dataConsigne.Montant_consigne,
            Prix_vente: dataConsigne.Montant_consigne,
            PrixFournisseurType: "prixnet",
            disabled: true,
            quantity: dataConsigne.quantity,
            quantityPrep: 0,
            quantityReturned: 0,
            remise: 0,
            prixtotal: dataConsigne.Prix_euro,
            prixfinal: dataConsigne.Prix_euro,
            Code_marque: dataConsigne.Code_marque,
            Description: dataConsigne.Description,
            commentaire: dataConsigne.commentaire,
          });
          this.adddataconsigne(dataConsigne);
        } else {
          this.adddatanonconsigne({
            _id: dataConsigne._id,
            Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
            Prix_euro: dataConsigne.Montant_consigne,
            Prix_vente: dataConsigne.Montant_consigne,
            PrixFournisseurType: "prixnet",
            disabled: true,
            quantityPrep: dataConsigne.quantity,
            quantityReturned: 0,
            quantity: 0,
            remise: 0,
            prixtotal: dataConsigne.Prix_euro,
            prixfinal: dataConsigne.Prix_euro,
            Code_marque: dataConsigne.Code_marque,
            Description: dataConsigne.Description,
            commentaire: dataConsigne.commentaire,
          });
          this.adddatanonconsigne(dataConsigne);
        }
      } else {
        // if (
        //   this.tabClient.numcomptedasilva &&
        //   this.tabClient.numcomptedasilva != ""
        // ) {
        // }
        if (dataConsigne.Code_marque == "DA SILVA") {
          this.adddataconsigne(dataConsigne);
        } else {
          this.adddatanonconsigne(dataConsigne);
        }
      }
    },
    getModifier(objet) {
      this.cleardatadevis();
      this.savedatadevis({ ref: objet.Note, client: objet.Client });
      for (const artBdl of objet.Articles) {
        this.adddatadevismodif(artBdl);
      }
      this.$router.push("./devisClient");
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getInfosDevis(skipValue, limitValue);
        this.getInfosDevisLength();
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(`${process.env.VUE_APP_API}/devisSpaceClient/searchInClient`, {
            plateform: this.user.plateform,
            client: this.user.proprietaire,
            searchTerm: searchTerm,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          })
          .then((response) => {
            this.searchInProgress = false;
            this.infosDevis = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
  },
  mounted() {
    this.getInfosDevis(1, 10);
    this.getInfosDevisLength();
    this.pageSwitch();
  },
};
</script>

<style>
.tab-list:hover:nth-child(odd) {
  background-color: rgb(229 231 235);
  /* opacity: 0.6; */
}

.tab-list:hover:nth-child(even) {
  background-color: rgb(229 231 235);
  /* opacity: 0.6; */
}
</style>
